import { useCallback, useEffect, useMemo, useState } from "react";
import { isEmpty } from "lodash";

import { selectVersionConfig, versionsSlice } from "fond/api";
import { useAppDispatch, useAppSelector } from "fond/utils/hooks";
import localStorage from "fond/utils/localStorage";

export interface ReturnType {
  /**
   * A collection of layerIds and the childIds to include.
   *
   * Example:
   *  {
   *    myGroupId: ["layer1", "layer2"]
   *  }
   */
  filters: Record<string, string[]>;
  /**
   * Callback to change the current filtering applied to the layerId
   *
   * @param layerId The id of the layer whose children are being filtered.
   * @param childId The id of the child being toggled on or off.
   * @param childIds A collection of all childIds available to be filtered.
   * @param multiple Flag indicating if multiple layers can be set to visible at the same time.
   */
  onChange(layerId: string, childId: string, childIds: string[], multiple: boolean): void;
}

/**
 * Hook that updates a cached versionConfig by marking layers as "Excluded".
 * The filtered list is a whitelist of layers to be shown to the user.
 *
 * @param versionId the id of the versionConfig to be modified
 * @param defaultValues a set of default values to apply to the filters if no existing filters exist.
 */
export const useFilterLayers = (versionId?: string, defaultValues?: Record<string, string[]>): ReturnType => {
  const dispatch = useAppDispatch();
  const currentData = useAppSelector((state) => selectVersionConfig(state, versionId));
  const [filteredLayerIds, setFilteredLayerIds] = useState<Record<string, string[]>>(
    localStorage.getItem(`state.project.projects[${versionId}].layerFilters`, defaultValues || {})
  );

  useEffect(() => {
    const newFilteredLayerIds = { ...defaultValues, ...filteredLayerIds };
    localStorage.setItem(`state.project.projects[${versionId}].layerFilters`, newFilteredLayerIds);

    // We don't apply any filtering if no layer ids is supplied
    if (versionId && !isEmpty(newFilteredLayerIds)) {
      const inclusions = Object.values(newFilteredLayerIds).flat();
      dispatch(
        versionsSlice.util.updateQueryData("getVersionConfig", versionId, (draft) => {
          draft.Data.ids.forEach((id) => {
            if (draft.Data.entities[id]?.Type === "LAYER") {
              draft.Data.entities[id].Exclude = !inclusions.includes(String(id));
            } else if (draft.Data.entities[id]?.Type === "SUBLAYER") {
              draft.Data.entities[id].Exclude = !inclusions.includes(String(draft.Data.entities[id].ParentID));
            }
          });
        })
      );
    }
  }, [defaultValues, dispatch, filteredLayerIds, versionId, currentData]);

  /**
   * Callback function that handles the updating of the layers being filtered
   */
  const handleOnChange = useCallback((layerId: string, childId: string, optionIds: string[], multiple: boolean) => {
    if (multiple) {
      setFilteredLayerIds((prev) => {
        return {
          ...prev,
          [layerId]: prev[layerId]?.includes(childId) ? prev[layerId].filter((id) => id !== childId) : [...(prev[layerId] || []), childId],
        };
      });
    } else {
      setFilteredLayerIds((prev) => {
        return {
          ...prev,
          [layerId]: [childId],
        };
      });
    }
  }, []);

  const result = useMemo(() => {
    const newFilters = Object.keys(defaultValues || {}).reduce(
      (prev, curr) => {
        return {
          filters: {
            ...prev.filters,
            [curr]: filteredLayerIds[curr] || defaultValues?.[curr],
          },
        };
      },
      { filters: {} }
    ) as ReturnType;

    return { ...newFilters, onChange: handleOnChange };
  }, [defaultValues, filteredLayerIds, handleOnChange]);

  return result;
};
