import { Configuration, ConfigurationUpsert, GroupConfig, MultiProject, SystemsOfMeasurement } from "fond/types";
import { FilterConfiguration, LayerConfig, LayerStyle, SublayerConfig } from "fond/types/ProjectLayerConfig";
import { pickIDs } from "fond/utils";

import { cbgOverlayConfiguration, defaultCbgFilteredLayerIds } from "./cbgOverlayConfiguration";
import { defaultFccFilteredLayerIds, fccOverlayConfiguration } from "./fccOverlayConfiguration";
import { defaultSoilFilteredLayerIds, soilOverlayConfiguration } from "./soilOverlayConfiguration";
import { defaultSubareaFilteredLayerIds, subareaConfiguration, SubareaLayerId } from "./subareaConfiguration";

export interface FamilialLayerConfig {
  config: LayerConfig;
  descendants: (LayerStyle | SublayerConfig)[];
}

export enum OverlayIds {
  ROOT = "multiProjectOverlayGroup",
  FCC = "multiProjectFccGroup",
  DEMOGRAPHICS = "multiProjectCbgGroup",
  SOIL = "multiProjectSoilGroup",
}

export const defaultCityPlannerLayerFilters = {
  [OverlayIds.FCC]: defaultFccFilteredLayerIds,
  [OverlayIds.DEMOGRAPHICS]: defaultCbgFilteredLayerIds,
  [OverlayIds.SOIL]: defaultSoilFilteredLayerIds,
  [SubareaLayerId.ROOT]: defaultSubareaFilteredLayerIds,
};

/**
 * Generate the Configuration upsert based on the Multiproject Boundary & Areas.
 * A LayerConfig is generated for the Boundary & one for each Area.
 */
export const multiProjectConfiguration = (multiProject: MultiProject): ConfigurationUpsert => {
  const subareas = subareaConfiguration(multiProject.Areas);

  const { configs: fccConfigs, groupConfig: fccGroup } = fccOverlayConfiguration();
  const { configs: cbgConfigs, groupConfig: cbgGroup } = cbgOverlayConfiguration();
  const { configs: soilConfigs, groupConfig: soilGroup } = soilOverlayConfiguration(multiProject.SystemOfMeasurement);
  const overlayGroup = groupConfigTemplate(OverlayIds.ROOT, [fccGroup, cbgGroup, soilGroup], "Overlays", false);

  return [...subareas, overlayGroup, ...fccConfigs, fccGroup, ...cbgConfigs, cbgGroup, ...soilConfigs, soilGroup];
};

/**
 * Takes the phases and generates the style configur ation for the report build order map.
 */
export const generateMultiProjectMapConfiguration = (multiProject: MultiProject): Configuration => {
  const data = multiProjectConfiguration(multiProject);

  return {
    ID: "",
    Key: "",
    SourceID: "",
    Data: {
      ids: pickIDs(data),
      entities: Object.fromEntries(data.map((entity) => [entity.ID, entity])),
    },
    MapChildren: [...pickIDs(data.filter((entity) => entity.Type === "GROUP" && entity.ParentID === null))],
    Type: "MapLayerConfig",
  };
};

export const groupConfigTemplate = (
  id: string,
  children: Array<LayerConfig | GroupConfig>,
  label: string,
  visible = true,
  parentId?: string
): GroupConfig => ({
  ID: id,
  Type: "GROUP",
  Label: label,
  Key: null,
  Children: pickIDs(children),
  IsVisible: visible,
  GlobalPosition: 0,
  Position: 0,
  ParentID: parentId ?? null,
  RootID: null,
});

export const layerConfigTemplate = (
  id: string,
  key: string,
  label: string,
  styles: LayerStyle[],
  children?: string[],
  parentId?: string
): LayerConfig => ({
  ID: id,
  Label: label,
  IsVisible: true,
  Styles: pickIDs(styles),
  Position: 0,
  Type: "LAYER",
  GeometryType: "Polygon",
  GlobalPosition: 0,
  ParentID: parentId ?? null,
  Key: key,
  MaxZoomLevel: null,
  MinZoomLevel: null,
  Children: children ?? [],
});

export const sublayerTemplate = (
  id: string,
  key: string,
  layerId: string,
  label: string,
  filter: FilterConfiguration | null,
  styles: LayerStyle[]
): SublayerConfig => ({
  Type: "SUBLAYER",
  ParentID: layerId,
  FilterConfiguration: filter,
  Styles: pickIDs(styles),
  ID: id,
  Label: label,
  Key: key,
  IsVisible: true,
  Position: 0,
  GeometryType: "Polygon",
});

interface BucketConfiguration {
  id: string;
  label: string;
  color: string;
  filter: FilterConfiguration;
}

interface MakeBucketOptions {
  layerId: string;
  labels?: string[];
  attribute: string;
  splits: number[];
  colors: string[];
  includeLastBucket?: boolean;
  includeNullBucket?: boolean;
}

/**
 * Generate the buckets for a layer based on the splits and colors provided.
 */
export const makeBuckets = ({
  layerId,
  labels,
  attribute,
  splits,
  colors,
  includeLastBucket,
  includeNullBucket,
}: MakeBucketOptions): BucketConfiguration[] => {
  const buckets: BucketConfiguration[] = splits.slice(0, splits.length - 1).map((n, index) => {
    const endpoint = splits[index + 1];

    return {
      id: `${layerId}-${n}-${endpoint}`,
      label: labels?.[index] || `${n} - ${endpoint}`,
      color: colors[index],
      filter: {
        Mapbox: ["all", [">=", ["get", attribute], n], ["<", ["get", attribute], endpoint]],
        Type: "group" as const,
      },
    };
  });

  if (includeLastBucket) {
    const lastEndpoint = splits[splits.length - 1];
    const lastBucket = {
      id: `${layerId}-${lastEndpoint}+`,
      label: `${lastEndpoint}+`,
      color: colors[colors.length - 1],
      filter: {
        Mapbox: [">=", ["get", attribute], lastEndpoint],
        Type: "expression" as const,
      },
    };
    buckets.push(lastBucket);
  }
  if (includeNullBucket) {
    const nullBucket = {
      id: `${layerId}-null`,
      label: "N/A",
      color: "#8a8a8a",
      filter: {
        // Vector tile attributes cannot be null; the corresponding attribute is simply omitted
        // if the value is null.
        Mapbox: ["==", ["has", attribute], false],
        Type: "expression" as const,
      },
    };
    buckets.push(nullBucket);
  }
  return buckets;
};
