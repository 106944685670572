import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { ColDef, GridOptions, ValueFormatterParams, ValueGetterParams } from "@ag-grid-community/core";
import { AgGridReact } from "@ag-grid-community/react";
import { Check, Close } from "@mui/icons-material";
import { Box, CircularProgress, Paper, Typography } from "@mui/material";

import { selectReportsByMultiReportId, useGetReportsQuery } from "fond/api";
import { FullMultiReport, MultiReportCategory, Report, Store } from "fond/types";
import { GridId } from "fond/types/grids";
import { convertFeetToMeters } from "fond/utils";
import { formatFraction, formatNumber } from "fond/utils/number";
import { AgGrid, BlockSpinner } from "fond/widgets";

import { useStarred } from "../ItemMenu/hooks/useStarred";
import ReportRowMenu from "../ItemMenu/ReportRowMenu";
import EntityTypeCellRenderer from "../ProjectList/EntityCellRenderer";
import RowMenuCellRenderer from "../ProjectList/RowMenuCellRenderer";
import StatusCellRenderer from "../ProjectList/StatusCellRenderer";

interface IProps {
  multiReport: FullMultiReport;
  sliderRange: number[] | null;
  multiReportCategory: MultiReportCategory;
  setFilterRange(sliderRange: number[]): void;
}

const reportStatusEntries = {
  PENDING: null, // Should not be observable through the API
  IN_PROGRESS: {
    text: "Generating",
    icon: <CircularProgress size={18} color="info" />,
  },
  COMPLETE: {
    text: "Generated",
    icon: <Check />,
  },
  ERROR: {
    text: "Error",
    icon: <Close />,
  },
};

const gridOptions: GridOptions = {
  defaultColDef: {
    resizable: true,
    sortable: true,
  },
  domLayout: "autoHeight",
  rowClass: "no-alternate-background",
  animateRows: true,
  suppressGroupRowsSticky: true,
  suppressContextMenu: true,
  sideBar: false,
  rowGroupPanelShow: "never",
  pagination: true,
  paginationPageSize: 50,
  loadingOverlayComponent: BlockSpinner,
};

const SubareaReportList: React.FC<IProps> = ({ multiReport, sliderRange, multiReportCategory, setFilterRange }: IProps) => {
  const gridRef = useRef<AgGridReact>(null);
  const { isLoading, isFetching } = useGetReportsQuery(multiReport.ID);
  const [menuAnchor, setMenuAnchor] = useState<Element | undefined>(undefined);
  const [menuEntity, setMenuEntity] = useState<Report | undefined>(undefined);
  const reports = useSelector((state: Store) => selectReportsByMultiReportId(state, multiReport.ID) || []);
  const currentUsername = useSelector((state: Store) => state.cognito.user?.username);
  const { starred } = useStarred();
  const isStarred = useCallback((id: string) => starred.includes(id), [starred]);

  const openRowMenu = useCallback((e: React.MouseEvent<HTMLButtonElement, MouseEvent>, entity?: Report) => {
    setMenuAnchor(e.currentTarget);
    setMenuEntity(entity);
  }, []);

  const closeRowMenu = () => setMenuAnchor(undefined);

  /**
   * Format the filter value to the proper value.
   */
  const formatFilterValue = useCallback(
    (value: number): number => {
      if (["Irr", "Roi"].includes(multiReportCategory)) {
        return value * 100;
      }
      if (multiReportCategory === "CostPerMeter" && multiReport.SystemOfMeasurement === "imperial") {
        return convertFeetToMeters(value);
      }
      return value;
    },
    [multiReportCategory, multiReport.SystemOfMeasurement]
  );

  /**
   * Monitor the AgGrid for filter changes
   */
  const onFilterChanged = () => {
    const filteredValue: number[] = [];
    gridRef.current?.api.forEachNodeAfterFilterAndSort((node) => {
      filteredValue.push(node.data[multiReportCategory]);
    });
    setFilterRange([...new Set(filteredValue)].sort((a, b) => a - b));
  };

  useEffect(() => {
    if (sliderRange) {
      const newModel = {
        ...gridRef.current?.api?.getFilterModel(),
        [multiReportCategory as string]: {
          filterType: "number",
          operator: "AND",
          conditions: [
            {
              filterType: "number",
              type: "greaterThanOrEqual",
              filter: formatFilterValue(sliderRange?.at(0) as number),
            },
            {
              filterType: "number",
              type: "lessThanOrEqual",
              filter: formatFilterValue(sliderRange?.at(-1) as number),
            },
          ],
        },
      };
      gridRef.current?.api?.setFilterModel(newModel);
    }
  }, [sliderRange, multiReportCategory, formatFilterValue]);

  useEffect(() => {
    gridRef.current?.api?.setFilterModel({});
  }, [multiReport, multiReportCategory]);

  const revenueFilterParams = useMemo(
    () => ({
      defaultOption: "greaterThan",
      maxNumConditions: 2,
      filterOptions: ["greaterThan", "greaterThanOrEqual", "lessThan", "lessThanOrEqual", "inRange"],
    }),
    []
  );

  const costFilterParams = useMemo(
    () => ({
      ...revenueFilterParams,
      defaultOption: "lessThan",
    }),
    [revenueFilterParams]
  );

  const columns: ColDef[] = useMemo(
    () => [
      {
        field: "Name",
        headerName: "Name",
        flex: 3,
        minWidth: 144,
        cellRenderer: EntityTypeCellRenderer,
        cellRendererParams: {
          currentUsername,
          isStarred,
        },
      },
      {
        field: "Status",
        headerName: "Status",
        width: 120,
        cellRendererSelector: ({ data }) => {
          if (data?.EntityType === "report" && data?.Status !== null) {
            return {
              params: { status: data.Status, statusEntries: reportStatusEntries },
              component: StatusCellRenderer,
            };
          }
          return undefined;
        },
      },
      {
        field: "Npv",
        headerName: "NPV ($)",
        width: 120,
        valueFormatter: (params: ValueFormatterParams) => formatNumber(params.data?.Npv),
        floatingFilter: true,
        filter: "agNumberColumnFilter",
        filterParams: revenueFilterParams,
      },
      {
        field: "NetCost",
        headerName: "Cost ($)",
        width: 120,
        valueFormatter: (params: ValueFormatterParams) => formatNumber(params.data?.NetCost),
        floatingFilter: true,
        filter: "agNumberColumnFilter",
        filterParams: costFilterParams,
      },
      {
        field: "Irr",
        headerName: "IRR (%)",
        width: 92,
        valueGetter: (params: ValueGetterParams) => (params.data?.Irr ? params.data.Irr * 100 : params.data?.Irr),
        valueFormatter: (params: ValueFormatterParams) => formatFraction(params.data?.Irr, 0),
        floatingFilter: true,
        filter: "agNumberColumnFilter",
        filterParams: revenueFilterParams,
      },
      {
        field: "Roi",
        headerName: "ROI (%)",
        width: 92,
        valueGetter: (params: ValueGetterParams) => (params.data?.Roi ? params.data.Roi * 100 : params.data?.Roi),
        valueFormatter: (params: ValueFormatterParams) => formatFraction(params.data?.Roi, 0),
        floatingFilter: true,
        filter: "agNumberColumnFilter",
        filterParams: revenueFilterParams,
      },
      {
        field: "CostPerMeter",
        headerName: `Cost per ${multiReport.SystemOfMeasurement === "imperial" ? "foot" : "meter"} ($)`,
        width: 128,
        valueGetter: (params: ValueGetterParams) =>
          multiReport.SystemOfMeasurement === "imperial" && params.data?.CostPerMeter
            ? convertFeetToMeters(params.data.CostPerMeter)
            : params.data?.CostPerMeter,
        valueFormatter: (params: ValueFormatterParams) =>
          multiReport.SystemOfMeasurement === "imperial" && params.data?.CostPerMeter
            ? formatNumber(convertFeetToMeters(params.data.CostPerMeter))
            : formatNumber(params.data?.CostPerMeter),
        floatingFilter: true,
        filter: "agNumberColumnFilter",
        filterParams: costFilterParams,
      },
      {
        field: "CostPerPassing",
        headerName: "Cost per passing ($)",
        width: 152,
        valueFormatter: (params: ValueFormatterParams) => formatNumber(params.data?.CostPerPassing),
        floatingFilter: true,
        filter: "agNumberColumnFilter",
        filterParams: costFilterParams,
      },
      {
        field: "menu",
        headerName: "Menu",
        cellRenderer: RowMenuCellRenderer,
        cellRendererParams: {
          onClick: openRowMenu,
        },
        headerComponentParams: { displayName: "" },
        lockVisible: true,
        sortable: false,
        resizable: false,
        type: "rightAligned",
        width: 54,
      },
    ],
    [costFilterParams, currentUsername, isStarred, multiReport.SystemOfMeasurement, openRowMenu, revenueFilterParams]
  );

  return (
    <>
      <Box mt={5} mb={2}>
        <Typography variant="h3" component="span" fontWeight={700}>
          Subarea summary
        </Typography>
      </Box>
      <Paper square>
        <AgGrid
          id={GridId.CITY_PLANNER_REPORT_SUMMARY}
          ref={gridRef}
          columnDefs={columns}
          rowData={reports}
          gridOptions={gridOptions}
          variant="outlined"
          containerProps={{ flexGrow: 1 }}
          loading={isLoading || isFetching}
          size="large"
          onFilterChanged={onFilterChanged}
        />
      </Paper>
      {menuEntity && <ReportRowMenu report={menuEntity} onMenuClose={closeRowMenu} anchorEl={menuAnchor} />}
    </>
  );
};

export default SubareaReportList;
